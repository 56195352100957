import Gallery from "../../Components/Gallery/gallery"

function Home (){
   return(
      <div className="home-contenant">
        <Gallery/>

      </div>
   )
}
export default Home