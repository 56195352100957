import "../Bouton/bouton.css"


function Bouton (){
    return(
        <div>
            <button className="liens-components">
            <a href="https://widget.treatwell.fr/salon/majestueuse-mane/?utm_source=partner&utm_medium=salon-site-embedded-book-now-widget">Prendre rendez-vous</a>
            </button>
        </div>
    )
}
export default Bouton