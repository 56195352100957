import { Link } from "react-router-dom";
import "../Nav/nav.css"

function Nav (){
    return(
        <div className="nav-contenant">
            <Link to="/" className="link">Accueil</Link>
            <a href="https://widget.treatwell.fr/salon/majestueuse-mane/?utm_source=partner&utm_medium=salon-site-embedded-book-now-widget">Rendez-vous</a>
            <Link to="/contact"className="formulaire">Contact</Link>
        </div>
    )
}
export default Nav